.tailwind ul.bottle-list {
  li {
    padding-left: 80px;
    background-image: url("data:image/svg+xml,%3Csvg width='54' height='20' viewBox='0 0 54 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M53.0828 7.24955C52.8954 7.10788 52.6696 7.06742 52.3972 7.06742C51.1496 7.06742 50.8686 7.34831 50.8686 7.34831L46.0807 7.34831C42.231 7.34831 40.1641 5.45617 38.1279 3.59217C36.1514 1.78282 34.2038 0 30.6829 0L4.18069 0C1.95531 0 0.76395 0.516854 0.76395 0.516854C0.76395 0.516854 -0.000320435 0.516854 -0.000320435 10C-0.000320435 19.4831 0.76395 19.4831 0.76395 19.4831C0.76395 19.4831 1.95531 20 4.18069 20L30.6829 20C34.2038 20 36.1514 18.2172 38.1279 16.4078C40.1641 14.5438 42.231 12.6517 46.0807 12.6517H50.8686C50.8686 12.6517 51.1496 12.9326 52.3972 12.9326C52.6696 12.9326 52.8954 12.8921 53.0828 12.7504C53.9932 12.7504 53.9932 12.0449 53.9932 10C53.9932 7.95506 53.9932 7.24955 53.0828 7.24955Z' fill='%233300FF'/%3E%3C/svg%3E");
    background-position: 0 8px;
    background-size: 54px 20px;
    background-repeat: no-repeat;
    font-size: 30px;
    line-height: 37.5px;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  @media (max-width: 1024px) {
    li {
      padding-left: 0px;
      padding-top: 30px;
      background-position: 0 0;
      background-size: 54px 20px;
    }
  }
}

input.is-invalid {
  border: 2px solid red !important;
}

.tailwind .trix-content {
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 22.5px;

  @media (min-width: 1440px) {
    font-size: 25px;
    line-height: 31.25px;
  }

  h1 {
    margin-top: 60px;
    font-family: 'Krona One';
    font-size: 30px;
    line-height: 37.5px;
    letter-spacing: -0.05em;

    @media (min-width: 1440px) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  a {
    text-decoration: underline;
    color: #3300FF;
  }

  ul {
    li {
      padding-left: 80px;
      background-image: url("data:image/svg+xml,%3Csvg width='54' height='20' viewBox='0 0 54 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M53.0828 7.24955C52.8954 7.10788 52.6696 7.06742 52.3972 7.06742C51.1496 7.06742 50.8686 7.34831 50.8686 7.34831L46.0807 7.34831C42.231 7.34831 40.1641 5.45617 38.1279 3.59217C36.1514 1.78282 34.2038 0 30.6829 0L4.18069 0C1.95531 0 0.76395 0.516854 0.76395 0.516854C0.76395 0.516854 -0.000320435 0.516854 -0.000320435 10C-0.000320435 19.4831 0.76395 19.4831 0.76395 19.4831C0.76395 19.4831 1.95531 20 4.18069 20L30.6829 20C34.2038 20 36.1514 18.2172 38.1279 16.4078C40.1641 14.5438 42.231 12.6517 46.0807 12.6517H50.8686C50.8686 12.6517 51.1496 12.9326 52.3972 12.9326C52.6696 12.9326 52.8954 12.8921 53.0828 12.7504C53.9932 12.7504 53.9932 12.0449 53.9932 10C53.9932 7.95506 53.9932 7.24955 53.0828 7.24955Z' fill='%233300FF'/%3E%3C/svg%3E");
      background-position: 0 8px;
      background-size: 54px 20px;
      background-repeat: no-repeat;
      font-size: 30px;
      line-height: 37.5px;

      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    @media (max-width: 1024px) {
      li {
        padding-left: 0px;
        padding-top: 30px;
        background-position: 0 0;
        background-size: 54px 20px;
      }
    }
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
}
